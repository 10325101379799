import React, { useState } from "react";
import "./RequestButton.css";
import Loader from "../../Feedback/Loader/Loader";

function RequestButton({ isInLibrary, isRequested }) {
  const [localIsRequested, setLocalIsRequested] = useState(false);
  const handleClickRequestButton = () => {
    if (localIsRequested) {
      return;
    }
    setLocalIsRequested(true);
  };
  const renderButton = () => {
    if (isInLibrary === null) return <Loader />;
    if (isInLibrary === true)
      return <div className="already-in-library">Already in Plex ✅</div>;
    if (isInLibrary === false) {
      return (
        <button
          onClick={handleClickRequestButton}
          className={`request-btn ${
            localIsRequested || isRequested ? "requested" : ""
          }`}
        >
          {localIsRequested || isRequested
            ? "Movie Requested! 😎"
            : "Request Movie 📼"}
        </button>
      );
    }
  };
  return <div className="request-btn-container">{renderButton()}</div>;
}

export default RequestButton;
