import React from "react";
import "./MetaBar.css";
import getGenres from "../../../helpers/getGenres.js";
import Genre from "./Genre";

function MetaBar({releaseDate, genres}){
    const date = new Date(releaseDate);
    const year = date.getFullYear();
    
    return (<div className="meta-bar">
        <span className="meta-year">{year}</span>
        <Genre genres={getGenres(genres)} />
        
    </div>)
}

export default MetaBar;