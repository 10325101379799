import React from "react";
import { enableTruncateDescription } from "../../../config";
import "./Description.css";
function Description({ description }) {
  const renderDesc = () => {
    const cutLength = 170;
    if (!enableTruncateDescription || description.length < cutLength)
      return <span>{description}</span>;
    return <span>{description.slice(0, cutLength).trim()}...</span>;
  };
  return <div className="description">{renderDesc()}</div>;
}
export default Description;
