import React from "react";
import './Poster.css'

function Poster({imgUrl, onClick}){
    return (<img 
                onClick={onClick} 
                className="movie-poster animate__animated animate__fadeIn" 
                style={{
                    animationDelay: (Math.random() + 0.25) + "s"
                }}
                src={imgUrl} 
            />)
}

export default Poster;