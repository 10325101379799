import React from "react";
import "./NothingFound.css";

function NothingFound() {
    return (<div className="nothing-found">
        <img className="nothing-found-image" src="./assets/img/nothing-found.png" />
        <h1 className="nothing-found-title">Couldn't find anything 😲</h1>
    </div>)
}

export default NothingFound;