import axios from "axios";
import { TMDB_AUTH, MOVIES_LIBRARY_URL } from "../../config";

const BASE = `https://api.themoviedb.org/3/`;

export const getPopularMovies = async (page = 1) => {
  const response = await axios.get(
    `${BASE}movie/popular?api_key=${TMDB_AUTH}&language=en-US&page=${page}`
  );
  return response;
};
export const getTopRatedMovies = async (page = 1) => {
  const response = await axios.get(
    `${BASE}movie/top_rated?api_key=${TMDB_AUTH}&language=en-US&page=${page}`
  );
  return response;
};
export const getNowPlayingMovies = async (page = 1) => {
  const response = await axios.get(
    `${BASE}movie/now_playing?api_key=${TMDB_AUTH}&language=en-US&page=${page}`
  );
  return response;
};

export const searchMovies = async (query = "", page = 1) => {
  const response = await axios.get(
    `${BASE}search/movie?api_key=${TMDB_AUTH}&language=en-US&page=${page}&query=${query}`
  );
  console.log(`API: searchMovies("${query}"): `, response.data.results);
  const { results } = response?.data;
  return results;
};

export const checkMovieInLibrary = async (movieDbId = 99999) => {
  try {
    const response = await axios.get(`${MOVIES_LIBRARY_URL}${movieDbId}`);
    console.log(
      `Checked movie id: ${movieDbId}, isInLibrary? `,
      response.data?.success
    );
    if (response.data?.success) {
      console.log("Movie is already in PLEX Library");
    }
    return response.data;
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};
