import React, { useEffect, useState } from "react";
import "./App.css";
import "./mobile.css";
import {
  TMDB_AUTH,
  isPaginationEnabled,
  CATEGORIES,
  initialCategory,
} from "./config";
import {
  getNowPlayingMovies,
  getPopularMovies,
  getTopRatedMovies,
  searchMovies,
} from "./api/tmdb";
import MovieList from "./components/MovieList/MovieList";
import MovieModal from "./components/MovieModal/MovieModal";
import Header from "./components/Header/Header";
import Loader from "./components/Feedback/Loader/Loader";
import NothingFound from "./components/Feedback/NothingFound/NothingFound";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(true);
  const [isNothingFound, setIsNothingFound] = useState(false);
  const [movies, setMovies] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState(initialCategory);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    console.log(`Fetching Movies: ${page}`);
    fetchMovies(page === 1);
  }, [page, category]);
  useEffect(() => {
    // Enable pagination
    if (isPaginationEnabled) {
      window.addEventListener("scroll", function (ev) {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          // you're at the bottom of the page
          console.log("Bottom of page reached.");
          setPage((prevPage) => prevPage + 1);
        }
      });
    }
  }, []);
  const handleMovieSelect = (movie) => {
    console.log(`Selected Movie: ${movie.title}`, movie);
    setSelectedMovie(movie);
  };
  const fetchMovies = (clearBeforeFetch) => {
    console.log(`Fetching ${category} movies`);
    switch (category) {
      case "popular":
        // Get the movies
        getPopularMovies(page).then((results) => {
          if (clearBeforeFetch) {
            console.log("Movies cleared!");

            setMovies([...results.data.results]);
          } else {
            setMovies([...movies, ...results.data.results]);
          }
          setIsLoading(false);
        });
        break;
      case "top-rated":
        // Get the movies
        getTopRatedMovies(page).then((results) => {
          if (clearBeforeFetch) {
            console.log("Movies cleared!");

            setMovies([...results.data.results]);
          } else {
            setMovies([...movies, ...results.data.results]);
          }
          setIsLoading(false);
        });
        break;
      case "now-playing":
        getNowPlayingMovies(page).then((results) => {
          if (clearBeforeFetch) {
            console.log("Movies cleared!");

            setMovies([...results.data.results]);
          } else {
            setMovies([...movies, ...results.data.results]);
          }
          setIsLoading(false);
        });
        break;
      case "search":
        console.log("Fetching more results");
      default:
        break;
    }
  };
  const handleUpdateMovieResults = (
    movieListArr,
    pageId,
    currentMovies = []
  ) => {
    const filteredMovies = movieListArr.filter((movie) => {
      return movie["poster_path"] !== null;
    });
    if (!filteredMovies.length) {
      setIsNothingFound(true);
    }
    setMovies([...filteredMovies]);
  };
  const handleSearchMovies = async (query, pageId = 1) => {
    setMovies([]);
    console.log(`searching movies for "${query}"`);
    if (query === "") {
      setIsNothingFound(true);
      return;
    }
    const apiSearch = async () => {
      setIsSearching(true);
      setCategory("search");
      const movies = await searchMovies(query, pageId);
      handleUpdateMovieResults(movies);
      setIsSearching(false);
    };
    apiSearch();
  };
  const handleChangeCategory = (categoryLabel) => {
    setSearchValue("");
    setPage(1);
    if (categoryLabel === category) return;
    if (!CATEGORIES.includes(categoryLabel)) {
      setCategory("popular");
      return;
    }
    setCategory(categoryLabel);
    console.log(`Category set to: ${categoryLabel}`);
    fetchMovies(true);

    return;
  };
  return (
    <main className={selectedMovie ? "modal-open" : ""}>
      <Header
        onSearchMovies={handleSearchMovies}
        onCategoryClick={handleChangeCategory}
        category={category}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <MovieList movies={movies} onMovieSelect={handleMovieSelect} />
      {(isLoading || isSearching) && <Loader />}
      {isNothingFound && !isSearching && movies.length === 0 && (
        <NothingFound />
      )}
      {selectedMovie && (
        <MovieModal
          movie={selectedMovie}
          closeModal={() => setSelectedMovie(null)}
        />
      )}
    </main>
  );
}

export default App;
