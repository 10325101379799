import React from "react";
import "./Loader.css";
function Loader() {
  return (
    <div
      className="loader-container animate__animated animate__fadeIn"
      style={{ animationDelay: "1s" }}
    >
      <div className="loader"></div>
    </div>
  );
}
export default Loader;
