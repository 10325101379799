import React, { useState } from "react";
import { initialCategory } from "../../../config";
import "./Categories.css";

function Categories({ onCategoryClick, category }) {
  return (
    <div className="categories-container">
      <div
        className={`category-label ${
          category === "popular" ? "active" : "inactive"
        }`}
        onClick={() => {
          onCategoryClick("popular");
        }}
      >
        Popular
      </div>
      <div
        className={`category-label ${
          category === "now-playing" ? "active" : "inactive"
        }`}
        onClick={() => {
          onCategoryClick("now-playing");
        }}
      >
        Now Playing
      </div>
      <div
        className={`category-label ${
          category === "top-rated" ? "active" : "inactive"
        }`}
        onClick={() => {
          onCategoryClick("top-rated");
        }}
      >
        Top Rated
      </div>
    </div>
  );
}

export default Categories;
