import React, { useState, useCallback } from "react";
import debounce from "lodash.debounce";
import "./SearchBar.css";
function SearchBar({ onSearchMovies, searchValue, setSearchValue }) {
  const debouncedSearchMovies = useCallback(
    debounce((event) => {
      onSearchMovies(event.target.value);
    }, 1000),
    []
  );
  const handleInputChange = (event) => {
    const { value } = event.target;
    console.log(`Searching for: "${value}"`);
    if (value.endsWith("  ")) {
      return;
    }
    if (value.startsWith(" ")) {
      setSearchValue(value.trim());
      return;
    }
    setSearchValue(value);
    debouncedSearchMovies(event);
  };
  return (
    <div className="search-input-container">
      <input
        onChange={handleInputChange}
        className="search-input"
        value={searchValue}
        type="text"
        placeholder="Type a movie"
      />
    </div>
  );
}

export default SearchBar;
