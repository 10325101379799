import React from "react";
import SearchBar from "./SearchBar/SearchBar";
import "./Header.css";
import Categories from "./Categories/Categories";
function Header({
  onSearchMovies,
  onCategoryClick,
  category,
  searchValue,
  setSearchValue,
}) {
  const handleClickHeader = () => {
    window.location.reload();
  };
  return (
    <header>
      <h1 onClick={handleClickHeader}>📽️ Movies 🍿</h1>
      <SearchBar
        onSearchMovies={onSearchMovies}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <Categories onCategoryClick={onCategoryClick} category={category} />
    </header>
  );
}
export default Header;
