import React, { useState, useEffect } from "react";
import { TMDB_AUTH } from "../../config";
import { checkMovieInLibrary } from "../../api/tmdb";
import Description from "./Details/Description";
import MetaBar from "./Details/MetaBar";
import RequestButton from "./Actions/RequestButton";
import CloseModal from "./Actions/CloseModal";
import "./MovieModal.css";

function MovieModal({ isOpen, movie, closeModal }) {
  const {
    title,
    popularity,
    id,
    original_language: language,
    overview: description,
    release_date: releaseDate,
    adult,
    genre_ids: genres,
    backdrop_path: backdrop,
    poster_path: poster,
  } = movie;

  const [apiMovie, setApiMovie] = useState(null);
  const [traktLink, setTraktLink] = useState("");
  const [isInLibrary, setIsInLibrary] = useState(null);
  const [isRequested, setIsRequested] = useState(null);

  useEffect(() => {
    const getMovie = async () => {
      const r = await fetch(
        `https://api.themoviedb.org/3/movie/${id}?api_key=${TMDB_AUTH}&language=en-US`
      )
        .then((res) => res.json())
        .then((data) => data);

      setApiMovie(r);
      console.log(r);
      setTraktLink(`https://trakt.tv/movies/${r["imdb_id"]}`);

      // Check movie in library
      const libraryMovie = await checkMovieInLibrary(id);

      if (libraryMovie.dateAdded) {
        console.log("Movie already in library");
        setIsInLibrary(true);
      } else if (libraryMovie.dateRequested) {
        console.log("Movie already requested");
        setIsRequested(true);
        setIsInLibrary(false);
      } else {
        setIsRequested(false);
        setIsInLibrary(false);
      }
    };
    getMovie();
  }, []);
  const shadowStyle = {
    backdropFilter: "blur(5px) saturate(1.5)",
  };
  return (
    <>
      {" "}
      {movie && (
        <div className="movie-modal animate__animated animate__fadeIn">
          <div className="modal">
            <CloseModal onClick={closeModal} />
            <div
              className="backdrop"
              style={{
                backgroundImage: `url(https://image.tmdb.org/t/p/w500/${backdrop})`,
              }}
            >
              <img
                className="modal-poster"
                src={`https://image.tmdb.org/t/p/w500/${poster}`}
              />
              <div className="shadow" style={shadowStyle}></div>
            </div>
            <div className="details">
              <h1 className="movie-title">{title}</h1>
              {apiMovie && apiMovie?.tagline && (
                <h4 className="movie-tagline">{apiMovie?.tagline}</h4>
              )}
              <MetaBar releaseDate={releaseDate} genres={genres} />
              <Description description={description} />
              <div className="links">
                <a
                  className="link"
                  id="link-trakt"
                  target="_blank"
                  href={traktLink}
                >
                  Trakt.tv
                </a>
              </div>
            </div>
            <div className="actions">
              <RequestButton
                isInLibrary={isInLibrary}
                isRequested={isRequested}
              />
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
}
export default MovieModal;
