import React, {useState} from "react";
import Poster from "./Poster"
import "./Movie.css"
import MovieModal from "../MovieModal/MovieModal"
function Movie({movie, onMovieSelect}) {
    const [modalOpen, setModalOpen] = useState(false);
    const imgUrl = `https://image.tmdb.org/t/p/w500/${movie['poster_path']}`

    const onClick = () => {
        onMovieSelect(movie)
    }
    
    return (<div className="movie">
        <Poster imgUrl={imgUrl} onClick={onClick} />
    </div>)  
}

export default Movie