import React from "react";
import './Genre.css'
function Genre({genres=[]}){
    const Chip = ({genre}) => {
        return <span className="genre">{genre}</span>
    }
    const renderGenres = () => {
        return genres.map(g => <Chip key={g} genre={g} />);
    }
    return (<div className="genres">
        {renderGenres()}
    </div>)
}

export default Genre;